import React from "react";
import { useNavigate } from "react-router-dom";

const LoginCta = ({ callback }) => {
  const navigate = useNavigate();
  return (
    <button
      className="cta-button"
      onClick={() => {
        if (callback) {
          callback();
        }
        navigate("/login");
      }}
    >
      Login
    </button>
  );
};

export default LoginCta;
