import React, { createContext, useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import Modal from "./Modal";
import Toast from "./Toast";

export const MyContext = createContext();

const Layout = ({ children, noNav = false }) => {
  const callMethod = () => {
    openModal();
  };

  const [showModal, setShowModal] = useState(false);
  const [showToast, setShowToast] = useState(false);

  const openModal = () => setShowModal(true);

  const handleFormSubmit = (event) => {
    event.preventDefault(); // Prevent the default form submission

    const formData = new FormData(event.target);
    const data = Object.fromEntries(formData);

    const payload = { ...data };

    window.emailjs.send("glomopay_website", "glomopay_website", payload).then(
      () => {
        setShowToast(true);
        setShowModal(false);
        setTimeout(() => setShowToast(false), 3000);
      },
      (error) => {
        console.log("FAILED...", error);
      }
    );
  };

  return (
    <MyContext.Provider value={callMethod}>
      <>
        <Header noNav={noNav} />
        {children}
        {!noNav && <Footer />}
      </>
      {showModal && (
        <Modal
          isOpen={showModal}
          onClose={() => setShowModal(false)}
          handleFormSubmit={(event) => handleFormSubmit(event)}
        />
      )}
      {showToast && <Toast message="Message sent successfully!" />}
    </MyContext.Provider>
  );
};

export default Layout;
