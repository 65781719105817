import React, { Suspense, lazy } from "react";
import { Route, BrowserRouter, Routes } from "react-router-dom";
import Layout from "./components/Layout";

const HomePage = lazy(() => import("./pages/HomePage"));
const Legal = lazy(() => import("./pages/Legal"));
const AboutUs = lazy(() => import("./pages/AboutUs"));
const Checkout = lazy(() => import("./pages/products/Checkout"));
const Treasury = lazy(() => import("./pages/products/Treasury"));
const Payouts = lazy(() => import("./pages/products/Payouts"));
const PaymentLinks = lazy(() => import("./pages/products/PaymentLinks"));
const RecurringPayments = lazy(() =>
  import("./pages/products/RecurringPayments")
);
const MarketPlaces = lazy(() => import("./pages/solutions/MarketPlaces"));
const FinancialInstitutions = lazy(() =>
  import("./pages/solutions/FinancialInstitutions")
);
const GlobalBusiness = lazy(() => import("./pages/solutions/GlobalBusiness"));
const Login = lazy(() => import("./pages/Login"));

export default function RootRoutes() {
  return (
    <BrowserRouter>
      <Suspense fallback={<div />}>
        <Routes>
          <Route
            path="/"
            element={
              <Layout>
                <HomePage />
              </Layout>
            }
          />
          <Route
            path="/legal"
            element={
              <Layout>
                <Legal />
              </Layout>
            }
          />
          <Route
            path="/about-us"
            element={
              <Layout>
                <AboutUs />
              </Layout>
            }
          />
          <Route
            path="/payment-links"
            element={
              <Layout>
                <PaymentLinks />
              </Layout>
            }
          />

          <Route
            path="/products/checkout"
            element={
              <Layout>
                <Checkout />
              </Layout>
            }
          />

          <Route
            path="/products/payment-links"
            element={
              <Layout>
                <PaymentLinks />
              </Layout>
            }
          />

          <Route
            path="/products/treasury"
            element={
              <Layout>
                <Treasury />
              </Layout>
            }
          />

          <Route
            path="/products/payouts"
            element={
              <Layout>
                <Payouts />
              </Layout>
            }
          />

          <Route
            path="/products/recurring-payments"
            element={
              <Layout>
                <RecurringPayments />
              </Layout>
            }
          />

          <Route
            path="/solutions/marketplaces"
            element={
              <Layout>
                <MarketPlaces />
              </Layout>
            }
          />

          <Route
            path="/solutions/financial-institutions"
            element={
              <Layout>
                <FinancialInstitutions />
              </Layout>
            }
          />

          <Route
            path="/solutions/global-business"
            element={
              <Layout>
                <GlobalBusiness />
              </Layout>
            }
          />

          <Route
            path="/login"
            element={
              <Layout noNav>
                <Login />
              </Layout>
            }
          />

          <Route path="*" element={<div>404</div>} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
}
