import React, { useEffect, useState } from "react";
import "../styles/modal.scss";
import CloseIcon from "../assets/closeIcon.svg";

const Modal = ({ isOpen, onClose, handleFormSubmit }) => {
  const [isFilled, setIsFilled] = useState(false);
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    // Cleanup when the component unmounts
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isOpen]);

  useEffect(() => {
    const interval = setInterval(checkIfFormIsFilled, 3000);

    // Cleanup when the component unmounts
    return () => {
      clearInterval(interval);
    };
  }, []);

  const checkIfFormIsFilled = () => {
    const form = document.getElementById("contactForm");
    const formData = new FormData(form);
    const isFilled = Array.from(formData.values()).every((field) => field);
    setIsFilled(isFilled);
  };

  if (!isOpen) return null;

  return (
    <>
      <div className="modal-overlay" onClick={onClose}>
        <div className="modal-content" onClick={(e) => e.stopPropagation()}>
          <div className="d-flex justify-content-between">
            <div className="modal-title">Talk to us</div>
            <img
              className="modal-close"
              onClick={onClose}
              src={CloseIcon}
              alt="icon"
            />
          </div>
          <div className="mt-5">
            <form
              id="contactForm"
              onSubmit={(event) => handleFormSubmit(event)}
            >
              <div className="row gy-5">
                <div className="col-lg-12">
                  <div className="form-group">
                    <input
                      onBlur={checkIfFormIsFilled}
                      type="text"
                      id="name"
                      name="name"
                      required
                      className="form-control border-bottom-only"
                      placeholder="Name"
                    />
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="form-group">
                    <input
                      onBlur={checkIfFormIsFilled}
                      type="email"
                      id="email"
                      name="email"
                      required
                      className="form-control border-bottom-only"
                      placeholder="Email ID"
                    />
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="form-group">
                    <input
                      onBlur={checkIfFormIsFilled}
                      type="text"
                      id="business_name"
                      name="business_name"
                      required
                      className="form-control border-bottom-only"
                      placeholder="Business Name"
                    />
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="form-group">
                    <input
                      onBlur={checkIfFormIsFilled}
                      type="text"
                      id="phone"
                      name="phone"
                      required
                      pattern="^\+?[0-9]*$"
                      className="form-control border-bottom-only"
                      placeholder="Phone number"
                    />
                  </div>
                </div>
                <div className="col-lg-12">
                  <button
                    type="submit"
                    className={`submit-btn ${isFilled ? "active" : "inactive"}`}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Modal;
